import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import Layout from 'components/Layout';
import SearchTitle from 'components/SearchTitle';
import SearchInput from 'components/SearchInput';
import SearchResultsList from 'components/SearchResultsList';
import { ISearchResult } from 'components/SearchResultsList/models';

import algoliasearch from 'algoliasearch/lite';
import { isBrowser } from 'utils/browser';
import getQueryParamByName from 'utils/getQueryParamByName';
import { getGaviLayoutFlags } from 'utils/helpers';
import { gtmService } from 'services/gtmService';

import { ISearchResultPage } from './model';

import './SearchPage.scss';

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
) as any;
const articleIndex = client.initIndex(process.env.GATSBY_ALGOLIA_ARTICLE_INDEX);
const sympomIndex = client.initIndex(process.env.GATSBY_ALGOLIA_SYMPTOM_INDEX);
const discoveryIndex = client.initIndex(process.env.GATSBY_ALGOLIA_DISCOVERY_INDEX);
const productIndex = client.initIndex(process.env.GATSBY_ALGOLIA_PRODUCT_INDEX);

const SearchResultPage: FC<ISearchResultPage> = ({
  data: { searchResults: searchResultsData, siteSettings, commonSettings, header, footer },
}) => {
  const {
    resultsFor = '',
    singleResultFor = '',
    cantFind,
    searchAgain,
    btnText,
    ariaLabel,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    openGraphImageUrl,
    tags,
  } = searchResultsData;
  const lang = siteSettings?.lang;

  const [searchResults, setSearchResults] = useState<{
    results: ISearchResult[];
    query: string;
  }>({
    results: [],
    query: '',
  });

  const searchQuery = isBrowser() && decodeURI(getQueryParamByName('query') ?? '');
  const filter = { filters: `lang: ${lang}` };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeoutId;

    if (searchQuery) {
      Promise.all([
        articleIndex.search(searchQuery, filter),
        sympomIndex.search(searchQuery, filter),
        discoveryIndex.search(searchQuery, filter),
        productIndex.search(searchQuery, filter),
      ]).then(([articleSearch, sympomSearch, discoverySearch, productSearch]) => {
        setSearchResults({
          results: [
            ...(articleSearch?.hits || []),
            ...(sympomSearch?.hits || []),
            ...(discoverySearch?.hits || []),
            ...(productSearch?.hits || []),
          ],
          query: searchQuery,
        });
        timeoutId = gtmService.emitSearchResultsView(searchQuery);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery]);

  const totalResultsCount = searchResults.results.length;

  const { GAVIDESIGN } = getGaviLayoutFlags(tags, ['GAVIDESIGN']);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      isGaviDesign={GAVIDESIGN}
    >
      <div
        className={classNames('search-result', {
          'search-result--loaded': !isLoading,
        })}
      >
        <Container fluid>
          <SearchTitle
            searchResults={searchResults}
            totalResultsCount={totalResultsCount}
            singleResultFor={singleResultFor}
            resultsFor={resultsFor}
            cantFind={cantFind}
          />
          <SearchInput
            searchPlaceholder={header.searchPlaceholder}
            ariaClear={header.ariaClear}
            ariaSearchSubmit={header.ariaSearchSubmit}
            ariaSearchInput={header.ariaSearchInput}
            defaultValue={searchResults?.query}
          />
          {totalResultsCount ? null : searchResults.query ? <p>{searchAgain}</p> : null}
          <SearchResultsList
            results={searchResults.results}
            ariaLabel={ariaLabel}
            btnText={btnText}
          />
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SearchResultPageQuery($link: String = "", $lang: String) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    searchResults(link: { eq: $link }, lang: { eq: $lang }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        key
        value
      }
      openGraphImageUrl
      resultsFor
      singleResultFor
      cantFind
      searchAgain
      ariaLabel
      btnText
      tags {
        name
      }
    }
  }
`;

export default SearchResultPage;
