import React, { FC } from 'react';

import SearchBox from 'components/SearchInput/AlgoliaSearchBox/SearchBox';
import { IInputProps } from 'templates/SearchResultPage/model';

const AlgoliaSearchBox: FC<IInputProps> = React.memo(
  ({
    searchPlaceholder,
    ariaClear,
    defaultValue,
    ariaSearchSubmit,
    ariaSearchInput,
    searchBtnOutside,
    submitCallback,
  }) => {
    return (
      <SearchBox
        defaultValue={defaultValue}
        searchPlaceholder={searchPlaceholder}
        ariaClear={ariaClear}
        ariaSearchSubmit={ariaSearchSubmit}
        ariaSearchInput={ariaSearchInput}
        searchBtnOutside={searchBtnOutside}
        submitCallback={submitCallback}
      />
    );
  }
);

export default AlgoliaSearchBox;
