import React, { useEffect, useRef, FC, useState } from 'react';
import Button from 'components/common/Button';
import { globalHistory as history } from '@reach/router';

import IconCustom from 'components/common/IconCustom';
import { navigateToSearchPage } from '../utils';
import { ISearchBox } from './model';
import './SearchBox.scss';

const SearchBox: FC<ISearchBox> = ({
  searchPlaceholder,
  ariaClear,
  ariaSearchSubmit,
  ariaSearchInput,
  searchBtnOutside,
  defaultValue,
  submitCallback,
}) => {
  const [value, setValue] = useState('');
  const { location } = history;
  const pathArray = location.pathname.split('/');
  const prefix = pathArray.length && pathArray?.[1].length === 2 ? `/${pathArray?.[1]}/` : '/';
  const handleSearchFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    navigateToSearchPage(event, value, prefix);
    submitCallback && submitCallback();
  };

  const inputText = useRef(null);

  const clearInput = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setValue('');
    inputText?.current?.focus();
  };

  useEffect(() => {
    inputText?.current?.focus();
  }, []);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const searchBtn = (
    <Button
      ariaLabel={ariaSearchSubmit}
      variant="link"
      className="algolia-search__results-btn d-none d-md-inline-flex"
      type="submit"
    >
      <IconCustom icon="search" />
    </Button>
  );

  return (
    <form
      className="algolia-search"
      data-testid="algolia-search"
      noValidate
      role="search"
      onSubmit={handleSearchFormSubmit}
    >
      <div className="algolia-search__row">
        <input
          ref={inputText}
          className="algolia-search__input"
          type="search"
          placeholder={searchPlaceholder}
          aria-label={ariaSearchInput}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
        <div className="algolia-search__stretcher">
          <div className="algolia-search__stretcher-text">{value}</div>
          {value.length > 1 ? (
            <Button
              variant="icon"
              clickHandler={clearInput}
              ariaLabel={ariaClear}
              classes="algolia-search__clear"
            >
              <IconCustom icon="cancel" />
            </Button>
          ) : null}
        </div>
        {!searchBtnOutside ? searchBtn : null}
      </div>
      {searchBtnOutside ? searchBtn : null}
    </form>
  );
};

export default SearchBox;
