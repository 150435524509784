import React, { FC, useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { flattenDepth } from 'lodash';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { ISearchResultListProps, ISnippetValue } from './models';
import './SearchResultsList.scss';

const findMatchResult = (params): string => {
  const flattedParams: ISnippetValue[] = params ? flattenDepth(Object.values(params), 1) : [];

  return (
    flattedParams.find((item) => item.matchLevel !== 'none')?.value ||
    flattedParams.find((item) => item.matchLevel === 'none')?.value ||
    ''
  );
};

const SearchResultsList: FC<ISearchResultListProps> = ({ results, btnText, ariaLabel }) => {
  const { isNotMobile } = useScreenRecognition();
  const [toShow, setToShow] = useState<number>(0);
  const deviceToShow = {
    mobile: 6,
    desktop: 9,
  };
  const stepLoad = isNotMobile ? deviceToShow.desktop : deviceToShow.mobile;
  const loadMore = useCallback(() => setToShow(toShow + stepLoad), [toShow]);
  useEffect(() => {
    setToShow(stepLoad);
  }, [stepLoad]);

  return (
    <div className="search-results-list" data-testid="search-results-list">
      {results?.length
        ? results.slice(0, toShow).map(({ title, link, _snippetResult }) => (
            <div className="search-results-list__block" key={link}>
              <div className="search-results-list__title">
                <Link to={link}>
                  <DangerouslySetInnerHtml element="span" html={title} className="link-text" />
                  <IconCustom icon="chevron-right" />
                </Link>
              </div>
              <DangerouslySetInnerHtml
                html={findMatchResult(_snippetResult)}
                element="p"
                className="search-results-list__text"
              />
            </div>
          ))
        : null}

      {btnText && toShow < results.length ? (
        <Button
          ariaLabel={ariaLabel}
          clickHandler={loadMore}
          classes="search-results-list__btn"
          variant="primary"
        >
          {btnText}
        </Button>
      ) : null}
    </div>
  );
};

export default SearchResultsList;
