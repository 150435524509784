import React from 'react';
import { navigate } from 'gatsby';

export const navigateToSearchPage = (
  event: React.SyntheticEvent<HTMLElement>,
  searchQuery: string,
  prefix: string
): void => {
  event.preventDefault();
  navigate(`${prefix}search-results/?query=${searchQuery}`);
};

export default { navigateToSearchPage };
