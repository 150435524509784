import React, { FC } from 'react';

import { ISearchTitle } from './models';

import './SearchTitle.scss';

const SearchTitle: FC<ISearchTitle> = ({
  searchResults,
  totalResultsCount,
  singleResultFor,
  resultsFor,
  cantFind,
}) => (
  <h1 className="search-result__page-title">
    {`${
      searchResults.query
        ? totalResultsCount
          ? `${totalResultsCount} ${totalResultsCount === 1 ? singleResultFor : resultsFor}`
          : cantFind
        : resultsFor
    }`}
    <span>{searchResults.query || String.fromCharCode(160)}</span>
  </h1>
);

export default SearchTitle;
