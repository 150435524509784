import React, { FC } from 'react';

import AlgoliaSearchBox from './AlgoliaSearchBox';

import { ISearchInput } from './models';

const SearchInput: FC<ISearchInput> = ({
  searchPlaceholder,
  ariaClear,
  ariaSearchSubmit,
  ariaSearchInput,
  searchBtnOutside,
  defaultValue,
  submitCallback,
}) => (
  <div className="search-input">
    <AlgoliaSearchBox
      defaultValue={defaultValue}
      searchPlaceholder={searchPlaceholder}
      ariaClear={ariaClear}
      ariaSearchSubmit={ariaSearchSubmit}
      ariaSearchInput={ariaSearchInput}
      searchBtnOutside={searchBtnOutside}
      submitCallback={submitCallback}
    />
  </div>
);

export default SearchInput;
